import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import SectionHeader from '../components/common/section-header'
import PortfolioMasonry from '../components/sections/portfolio-masonry'
import HeroTitle from '../components/sections/hero-title'
import Pagination from '../components/common/Pagination'

import '../styles/pages/page-ourwork.scss'

const pageOurWork = ({ data, location, pageContext }) => {
	const page = data.ghostPage
	const posts = data.portfolioPosts.edges

	return (
		<>
			<MetaData
				data={data}
				location={location}
				type="website"
			/>
			<Helmet>
				<style type="text/css">{`${page.codeinjection_styles}`}</style>
			</Helmet>
			<Layout>
				<article className="page-our-work">
					<div className="dec-section--line-vertical bottom">
						<HeroTitle>
							<SectionHeader title="PORTFOLIO" h1={true} text="Everything is designed.<br>Few things are designed well." />
						</HeroTitle>
					</div>
					<section className="posts-masonry">
						<div className="container">
							<div className="row">
                <div className="col">
									<PortfolioMasonry postList={posts} />
                  <Pagination pageContext={pageContext} />
                </div>
              </div>
						</div>
					</section>
					<div className="container our-work__footer">
						<div className="dec-section--line-vertical">
							<p className="h5 text-align-center">When you believe a thing, believe it all the way, <br />implicitly and unquestionably</p>
						</div>
					</div>
				</article>
			</Layout>
		</>
	)
}

export const allPostsQueryPortfolio = graphql`
  query($skip: Int!, $limit: Int!) {
    portfolioPosts: allGhostPost(
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: published_at},
      filter: {tags: {elemMatch: {name: {eq: "#portfolio"}}}}) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-our-work"}}}) {
      ...GhostPageFields
    }
  }
`
export default pageOurWork
