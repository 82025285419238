import React from 'react'
import { XMasonry, XBlock } from "react-xmasonry";
import PortfolioCard from '../common/PortfolioCard'

class PortfolioMasonry extends React.Component {
  render() {
		let masonryChilds = this.props.postList.map(({ node }) => (
			<XBlock key={node.id}>
        <PortfolioCard post={node} />
      </XBlock>
		))

    return (
			<XMasonry
				className="portfolio-masonry"
				maxColumns={3}
				targetBlockWidth={500}
			>
				{masonryChilds}
			</XMasonry>
    )
  }
}

export default PortfolioMasonry
