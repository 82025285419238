import React from 'react'
import { Link } from 'gatsby'
import FeatureImage from '../common/FeatureImage'


class PortfolioCard extends React.Component {
  render() {
    const postTitle = this.props.post.title
    
    return (
      <Link to={`/${this.props.post.slug}/`} className="portfolio-card" title={postTitle}>
        <FeatureImage item={this.props.post} />
      </Link>
    )
  }
}

export default PortfolioCard